<template>
    <div class="page-exchange-result">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path:'/'}">我的积分筐</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path:'/IntegralMall/Exchange'}">确认积分订单</el-breadcrumb-item>
                <el-breadcrumb-item>兑换成功</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="exchange-result type2">
                <div class="pic">
                    <img src="@/assets/img/result-succ.png" alt="">
                </div>
                <div class="text">
                    <h3>兑换成功!</h3>
                    <p>感谢您的购买</p>
                </div>
                <a href="#/orderForBill" class="g-btn">
                    查看我的订单
                    <img src="@/assets/img/i-arr.png" alt="">
                </a>
            </div>
<!--            <div class="exchange-result">-->
<!--                <div class="pic">-->
<!--                    <img src="@/assets/img/icon-result.jpg" alt="">-->
<!--                </div>-->
<!--                <div class="text">-->
<!--                    <h3>兑换失败！</h3>-->
<!--                    <p>抱歉，您的积分不足！</p>-->
<!--                </div>-->
<!--                <div class="g-btn">-->
<!--                    查看兑换明细  →-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "Result"
}
</script>

<style scoped>

</style>
